import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import { auth } from './firebase';
import { User } from 'firebase/auth';
import Login from './components/Login';
import Home from './components/Home';
import CollectantenList from './components/CollectantenList';
import ResponsiveAppBar from './components/ResponsiveAppBar';

const theme = createTheme({
  // Hier kunt u het thema aanpassen indien nodig
});

function NavigationWrapper() {
  const navigate = useNavigate();
  const location = useLocation();

  const getNavValue = () => {
    switch (location.pathname) {
      case '/':
        return 0;
      case '/collectanten':
        return 1;
      default:
        return 0;
    }
  };

  const [navValue, setNavValue] = useState(getNavValue());

  useEffect(() => {
    setNavValue(getNavValue());
  }, [location]);

  const handleNavChange = (event: React.SyntheticEvent, newValue: number) => {
    setNavValue(newValue);
    switch (newValue) {
      case 0:
        navigate('/');
        break;
      case 1:
        navigate('/collectanten');
        break;
    }
  };

  return (
    <BottomNavigation
      value={navValue}
      onChange={handleNavChange}
      showLabels
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <BottomNavigationAction label="Home" icon={<HomeIcon />} />
      <BottomNavigationAction label="Collectanten" icon={<PeopleIcon />} />
    </BottomNavigation>
  );
}

function App() {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: User | null) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  if (!user) {
    return <Login />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ResponsiveAppBar />
        <div style={{ paddingTop: '20px', paddingBottom: '56px' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/collectanten" element={<CollectantenList />} />
          </Routes>
        </div>
        <NavigationWrapper />
      </Router>
    </ThemeProvider>
  );
}

export default App;