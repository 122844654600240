import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, Alert, Grid, Card, CardContent, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, styled, Chip, TextField, Box, Switch, FormControlLabel, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { firestore, auth } from '../firebase';
import { collection, getDocs, writeBatch, doc, addDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { useFirestoreError } from '../hooks/useFirestoreError';
import Papa from 'papaparse';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import AddIcon from '@mui/icons-material/Add';
import MapIcon from '@mui/icons-material/Map';

interface LogEntry {
  timestamp: Timestamp;
  field: string;
  oldValue: string;
  newValue: string;
}

interface Collectant {
  id: string;
  geslacht: string;
  voornaam: string;
  achternaam: string;
  straat: string;
  postcode: string;
  plaats: string;
  land: string;
  email: string;
  telefoon: string;
  status: 'bevestigd' | 'afgezegd' | 'onbekend';
  voorkeurstraten: string;
  opmerking: string;
  logboek: LogEntry[];
}

const StyledTypography = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  marginTop: '4px',
});

// Add this new styled component
const StatusCard = styled(Card)<{ status: string }>(({ theme, status }) => ({
  borderLeft: '5px solid',
  borderLeftColor: 
    status === 'bevestigd' ? 'rgba(0, 255, 0, 0.5)' :
    status === 'afgezegd' ? 'rgba(255, 0, 0, 0.5)' :
    'rgba(128, 128, 128, 0.5)', // for 'onbekend' or any other status
  transition: 'border-left-color 0.3s ease',
}));

const StatusFilter = styled(Chip)<{ active: boolean }>(({ theme, active }) => ({
  margin: theme.spacing(1),
  cursor: 'pointer',
  fontWeight: active ? 'bold' : 'normal',
  '&:hover': {
    opacity: 0.8,
  },
}));

function CollectantenList() {
  const [collectanten, setCollectanten] = useState<Collectant[]>([]);
  const { error, handleFirestoreError, setError } = useFirestoreError();
  const [file, setFile] = useState<File | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openNewCollectantDialog, setOpenNewCollectantDialog] = useState(false);
  const [newCollectant, setNewCollectant] = useState<Omit<Collectant, 'id'>>({
    geslacht: '',
    voornaam: '',
    achternaam: '',
    straat: '',
    postcode: '',
    plaats: '',
    land: 'Nederland',
    email: '',
    telefoon: '',
    status: 'onbekend',
    voorkeurstraten: '',
    opmerking: '',
    logboek: [] // Voeg dit toe
  });
  const [selectedCollectant, setSelectedCollectant] = useState<Collectant | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedCollectant, setEditedCollectant] = useState<Collectant | null>(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);

  const fetchCollectanten = useCallback(async () => {
    try {
      const collectantenRef = collection(firestore, 'collectanten');
      const snapshot = await getDocs(collectantenRef);
      const collectantenData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Collectant[];
      setCollectanten(collectantenData.sort((a, b) => a.achternaam.localeCompare(b.achternaam)));
      setError(null);
    } catch (error: any) {
      handleFirestoreError(error);
    }
  }, []);

  useEffect(() => {
    fetchCollectanten();
  }, [fetchCollectanten]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setError(null);
      setSuccess(null);
    }
  };

  const handleUpload = () => {
    if (!auth.currentUser) {
      setError('Je bent niet ingelogd. Log opnieuw in en probeer het nog eens.');
      return;
    }
    
    if (file) {
      setIsUploading(true);
      Papa.parse(file, {
        complete: async (results) => {
          const newCollectanten = results.data.slice(1).map((row: any) => ({
            geslacht: row[0],
            voornaam: row[1],
            achternaam: row[2],
            straat: row[3],
            postcode: row[4],
            plaats: row[5],
            land: row[6],
            email: row[7],
            telefoon: row[8],
            status: row[9] || 'onbekend',
            voorkeurstraten: row[10] || '',
            opmerking: row[11] || '',
          }));

          try {
            const batch = writeBatch(firestore);
            newCollectanten.forEach((collectant: any) => {
              const docRef = doc(collection(firestore, 'collectanten'));
              batch.set(docRef, collectant);
            });
            await batch.commit();
            setSuccess('Collectanten succesvol geüpload!');
            setError(null);
            fetchCollectanten();
          } catch (error: any) {
            console.error('Upload error:', error);
            handleFirestoreError(error);
            setSuccess(null);
          } finally {
            setIsUploading(false);
          }
        },
        error: (error) => {
          console.error('CSV parsing error:', error);
          setError('Er is een fout opgetreden bij het verwerken van het CSV-bestand.');
          setIsUploading(false);
        }
      });
    }
  };

  const handleNewCollectantChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setNewCollectant(prev => ({ ...prev, [name]: value }));
  };

  const handleAddNewCollectant = async () => {
    try {
      await addDoc(collection(firestore, 'collectanten'), newCollectant);
      setSuccess('Nieuwe collectant succesvol toegevoegd!');
      setOpenNewCollectantDialog(false);
      fetchCollectanten();
      setNewCollectant({
        geslacht: '',
        voornaam: '',
        achternaam: '',
        straat: '',
        postcode: '',
        plaats: '',
        land: 'Nederland',
        email: '',
        telefoon: '',
        status: 'onbekend',
        voorkeurstraten: '',
        opmerking: '',
        logboek: [] // Voeg dit toe
      });
    } catch (error: any) {
      console.error('Add new collectant error:', error);
      handleFirestoreError(error);
    }
  };

  const handleCardClick = (collectant: Collectant) => {
    setSelectedCollectant(collectant);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedCollectant(null);
    setEditMode(false);
    setEditedCollectant(null);
    setHasChanges(false);
  };

  const handleEditModeToggle = () => {
    setEditMode(!editMode);
    if (!editMode) {
      setEditedCollectant(selectedCollectant);
    } else {
      setEditedCollectant(null);
      setHasChanges(false);
    }
  };

  const handleEditChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!editedCollectant) return;
  
    const { name, value } = event.target;
    const updatedCollectant = { ...editedCollectant, [name]: value };
    setEditedCollectant(updatedCollectant);
    setHasChanges(JSON.stringify(updatedCollectant) !== JSON.stringify(selectedCollectant));
  };

  const handleSaveChanges = async () => {
    if (editedCollectant && selectedCollectant) {
      try {
        const docRef = doc(firestore, 'collectanten', editedCollectant.id);
        const { id, logboek, ...updateData } = editedCollectant;
        
        // Creëer nieuwe logboek entries
        const newLogEntries: LogEntry[] = [];
        Object.keys(updateData).forEach(key => {
          if (updateData[key as keyof typeof updateData] !== selectedCollectant[key as keyof typeof selectedCollectant]) {
            newLogEntries.push({
              timestamp: Timestamp.fromDate(new Date()), // Gebruik een nieuwe Date() in plaats van serverTimestamp()
              field: key,
              oldValue: String(selectedCollectant[key as keyof typeof selectedCollectant]),
              newValue: String(updateData[key as keyof typeof updateData])
            });
          }
        });

        // Voeg nieuwe logboek entries toe aan bestaande entries
        const updatedLogboek = [...(selectedCollectant.logboek || []), ...newLogEntries];

        await updateDoc(docRef, {
          ...updateData,
          logboek: updatedLogboek
        });

        setSelectedCollectant({...editedCollectant, logboek: updatedLogboek});
        setEditMode(false);
        setHasChanges(false);
        setSuccess('Wijzigingen succesvol opgeslagen!');
        fetchCollectanten();
      } catch (error: any) {
        console.error('Save changes error:', error);
        handleFirestoreError(error);
      }
    }
  };

  const generateGoogleMapsUrl = useCallback(() => {
    const startAddress = "Volmachtenlaan 17, 9331 BK, Norg";
    const unknownCollectanten = collectanten.filter(c => c.status === 'onbekend');
    
    if (unknownCollectanten.length === 0) {
      setError("Er zijn geen collectanten met status 'onbekend'.");
      return null;
    }

    const destinations = unknownCollectanten.map(c => `${c.straat}, ${c.postcode}, ${c.plaats}`);
    const waypoints = destinations.slice(0, -1).join('|'); // Google Maps heeft een limiet van 25 waypoints
    const destination = destinations[destinations.length - 1];

    const url = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(startAddress)}&destination=${encodeURIComponent(destination)}&waypoints=${encodeURIComponent(waypoints)}&travelmode=driving`;

    return url;
  }, [collectanten]);

  const handleOpenGoogleMaps = () => {
    const url = generateGoogleMapsUrl();
    if (url) {
      window.open(url, '_blank');
    }
  };

  const statusCounts = collectanten.reduce((acc, collectant) => {
    const status = collectant.status || 'onbekend';
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  const filteredCollectanten = activeFilter
    ? collectanten.filter(c => (c.status || 'onbekend') === activeFilter)
    : collectanten;

  const handleFilterClick = (status: string) => {
    setActiveFilter(activeFilter === status ? null : status);
  };

  return (
    <Container>
      <Box display="flex" justifyContent="center" flexWrap="wrap" mb={2}>
        <StatusFilter
          label={`Alle (${collectanten.length})`}
          onClick={() => setActiveFilter(null)}
          active={activeFilter === null}
          color="default"
        />
        <StatusFilter
          label={`Bevestigd (${statusCounts['bevestigd'] || 0})`}
          onClick={() => handleFilterClick('bevestigd')}
          active={activeFilter === 'bevestigd'}
          color="success"
        />
        <StatusFilter
          label={`Afgezegd (${statusCounts['afgezegd'] || 0})`}
          onClick={() => handleFilterClick('afgezegd')}
          active={activeFilter === 'afgezegd'}
          color="error"
        />
        <StatusFilter
          label={`Onbekend (${statusCounts['onbekend'] || 0})`}
          onClick={() => handleFilterClick('onbekend')}
          active={activeFilter === 'onbekend'}
          color="default"
        />
      </Box>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      
      <Box display="flex" justifyContent="flex-start" alignItems="center" mb={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<MapIcon />}
          onClick={handleOpenGoogleMaps}
          style={{ marginRight: '10px' }}
        >
          Open Route in Google Maps
        </Button>

        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenNewCollectantDialog(true)}
          style={{ marginRight: '10px' }}
        >
          Nieuwe Collectant Toevoegen
        </Button>

        <input
          accept=".csv"
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="raised-button-file">
          <Button variant="contained" component="span" style={{ marginRight: '10px' }}>
            CSV Bestand Kiezen
          </Button>
        </label>
        {file && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={isUploading}
          >
            {isUploading ? <CircularProgress size={24} /> : 'Uploaden'}
          </Button>
        )}
      </Box>

      {file && (
        <Typography variant="body2" gutterBottom>
          Geselecteerd bestand: {file.name}
        </Typography>
      )}

      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        {filteredCollectanten.map((collectant) => (
          <Grid item xs={12} sm={6} md={4} key={collectant.id}>
            <StatusCard 
              status={collectant.status || 'onbekend'} 
              onClick={() => handleCardClick(collectant)} 
              style={{ cursor: 'pointer' }}
            >
              <CardContent>
                <Typography variant="h6" component="div">
                  <strong>{collectant.voornaam} {collectant.achternaam}</strong>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {collectant.straat}, {collectant.postcode} {collectant.plaats}
                </Typography>
                {collectant.telefoon ? (
                  <StyledTypography variant="body2" color="text.secondary">
                    <PhoneIcon fontSize="small" style={{ marginRight: '8px' }} />
                    <a href={`tel:${collectant.telefoon}`}>{collectant.telefoon}</a>
                  </StyledTypography>
                ) : (
                  <Chip
                    label="Telefoonnummer onbekend"
                    color="error"
                    size="small"
                    style={{ marginTop: '4px' }}
                  />
                )}
                {collectant.email ? (
                  <StyledTypography variant="body2" color="text.secondary">
                    <EmailIcon fontSize="small" style={{ marginRight: '8px' }} />
                    {collectant.email}
                  </StyledTypography>
                ) : (
                  <Chip
                    label="E-mailadres onbekend"
                    color="error"
                    size="small"
                    style={{ marginTop: '4px' }}
                  />
                )}
              </CardContent>
            </StatusCard>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              {selectedCollectant?.voornaam} {selectedCollectant?.achternaam}
            </Typography>
            <Box>
              <FormControlLabel
                control={<Switch checked={editMode} onChange={handleEditModeToggle} />}
                label="Bewerken"
              />
              {editMode && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveChanges}
                  disabled={!hasChanges}
                  style={{ marginLeft: '10px' }}
                >
                  Opslaan
                </Button>
              )}
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {selectedCollectant && (
              <>
                <Grid item xs={12} sm={6}>
                  <Select
                    fullWidth
                    label="Geslacht"
                    name="geslacht"
                    value={editMode ? editedCollectant?.geslacht : selectedCollectant.geslacht}
                    onChange={handleEditChange as any}
                    disabled={!editMode}
                  >
                    <MenuItem value="Man">Man</MenuItem>
                    <MenuItem value="Vrouw">Vrouw</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Voornaam"
                    name="voornaam"
                    value={editMode ? editedCollectant?.voornaam : selectedCollectant.voornaam}
                    onChange={handleEditChange}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Achternaam"
                    name="achternaam"
                    value={editMode ? editedCollectant?.achternaam : selectedCollectant.achternaam}
                    onChange={handleEditChange}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Straat"
                    name="straat"
                    value={editMode ? editedCollectant?.straat : selectedCollectant.straat}
                    onChange={handleEditChange}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Postcode"
                    name="postcode"
                    value={editMode ? editedCollectant?.postcode : selectedCollectant.postcode}
                    onChange={handleEditChange}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Plaats"
                    name="plaats"
                    value={editMode ? editedCollectant?.plaats : selectedCollectant.plaats}
                    onChange={handleEditChange}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Land"
                    name="land"
                    value={editMode ? editedCollectant?.land : selectedCollectant.land}
                    onChange={handleEditChange}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Telefoonnummer"
                    name="telefoon"
                    value={editMode ? editedCollectant?.telefoon : selectedCollectant.telefoon}
                    onChange={handleEditChange}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="E-mailadres"
                    name="email"
                    type="email"
                    value={editMode ? editedCollectant?.email : selectedCollectant.email}
                    onChange={handleEditChange}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Select
                    fullWidth
                    label="Status"
                    name="status"
                    value={editMode ? editedCollectant?.status : selectedCollectant.status}
                    onChange={handleEditChange as any}
                    disabled={!editMode}
                  >
                    <MenuItem value="bevestigd">Bevestigd</MenuItem>
                    <MenuItem value="afgezegd">Afgezegd</MenuItem>
                    <MenuItem value="onbekend">Onbekend</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Voorkeurstraten"
                    name="voorkeurstraten"
                    value={editMode ? editedCollectant?.voorkeurstraten : selectedCollectant.voorkeurstraten}
                    onChange={handleEditChange}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Opmerking"
                    name="opmerking"
                    value={editMode ? editedCollectant?.opmerking : selectedCollectant.opmerking}
                    onChange={handleEditChange}
                    disabled={!editMode}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {selectedCollectant && selectedCollectant.logboek && selectedCollectant.logboek.length > 0 && (
            <Box mt={4}>
              <Typography variant="h6" gutterBottom>
                Logboek
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Tijdstip</TableCell>
                      <TableCell>Veld</TableCell>
                      <TableCell>Oude waarde</TableCell>
                      <TableCell>Nieuwe waarde</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedCollectant.logboek.map((entry, index) => (
                      <TableRow key={index}>
                        <TableCell>{entry.timestamp.toDate().toLocaleString()}</TableCell>
                        <TableCell>{entry.field}</TableCell>
                        <TableCell>{entry.oldValue}</TableCell>
                        <TableCell>{entry.newValue}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Sluiten
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openNewCollectantDialog} onClose={() => setOpenNewCollectantDialog(false)}>
        <DialogTitle>Nieuwe Collectant Toevoegen</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Select
                fullWidth
                label="Geslacht"
                name="geslacht"
                value={newCollectant.geslacht}
                onChange={handleNewCollectantChange as any}
              >
                <MenuItem value="Man">Man</MenuItem>
                <MenuItem value="Vrouw">Vrouw</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Voornaam"
                name="voornaam"
                value={newCollectant.voornaam}
                onChange={handleNewCollectantChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Achternaam"
                name="achternaam"
                value={newCollectant.achternaam}
                onChange={handleNewCollectantChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Straat"
                name="straat"
                value={newCollectant.straat}
                onChange={handleNewCollectantChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Postcode"
                name="postcode"
                value={newCollectant.postcode}
                onChange={handleNewCollectantChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Plaats"
                name="plaats"
                value={newCollectant.plaats}
                onChange={handleNewCollectantChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Land"
                name="land"
                value={newCollectant.land}
                onChange={handleNewCollectantChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Telefoonnummer"
                name="telefoon"
                value={newCollectant.telefoon}
                onChange={handleNewCollectantChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="E-mailadres"
                name="email"
                type="email"
                value={newCollectant.email}
                onChange={handleNewCollectantChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                fullWidth
                label="Status"
                name="status"
                value={newCollectant.status}
                onChange={handleNewCollectantChange as any}
              >
                <MenuItem value="bevestigd">Bevestigd</MenuItem>
                <MenuItem value="afgezegd">Afgezegd</MenuItem>
                <MenuItem value="onbekend">Onbekend</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Voorkeurstraten"
                name="voorkeurstraten"
                value={newCollectant.voorkeurstraten}
                onChange={handleNewCollectantChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Opmerking"
                name="opmerking"
                value={newCollectant.opmerking}
                onChange={handleNewCollectantChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNewCollectantDialog(false)}>Annuleren</Button>
          <Button onClick={handleAddNewCollectant}>Toevoegen</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Bevestig wijzigingen"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Weet je zeker dat je de wijzigingen wilt opslaan?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>Annuleren</Button>
          <Button onClick={() => {
            // Implement save changes logic here
            setOpenConfirmDialog(false);
          }} autoFocus>
            Bevestigen
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default CollectantenList;