import React from 'react';
import { Button, Container, Typography } from '@mui/material';
import { auth } from '../firebase';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

function Login() {
  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Google inloggen mislukt:', error);
    }
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" align="center" gutterBottom>
        Inloggen
      </Typography>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleGoogleLogin}
        style={{ marginTop: '20px' }}
      >
        Inloggen met Google
      </Button>
    </Container>
  );
}

export default Login;