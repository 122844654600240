import { useState, useCallback } from 'react';

export const useFirestoreError = () => {
  const [error, setError] = useState<string | null>(null);

  const handleFirestoreError = useCallback((error: any) => {
    console.error('Firestore error:', error);
    switch (error.code) {
      case 'permission-denied':
        setError('Je hebt geen toestemming om deze actie uit te voeren.');
        break;
      case 'unavailable':
        setError('De database is momenteel niet beschikbaar. Probeer het later opnieuw.');
        break;
      case 'failed-precondition':
        setError('Er is een probleem met de databaseconfiguratie. Neem contact op met de beheerder.');
        break;
      case 'unauthenticated':
        setError('Je bent niet ingelogd. Log opnieuw in en probeer het nog eens.');
        break;
      default:
        setError(`Er is een onverwachte fout opgetreden: ${error.message}`);
    }
  }, []);

  return { error, handleFirestoreError, setError };
};