import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDaCaR24GX123zCf3OuQfQFX3piI8cNHjE",
    authDomain: "svhgk-21984.firebaseapp.com",
    projectId: "svhgk-21984",
    storageBucket: "svhgk-21984.appspot.com",
    messagingSenderId: "372551438419",
    appId: "1:372551438419:web:5d7f7928d12ee9c15c76a6"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);

// This line is necessary to make the module declaration work
export {};